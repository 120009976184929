/*
* ink colors (with variations only for black and white)
*/
.btn-action-modal-qualification {
  padding: 0 !important;
  width: 100% !important; }

.btn-action-container-qualification {
  width: 100% !important;
  padding: 16px !important;
  border-radius: 8px !important;
  border: solid 1px #1D66DD !important;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important; }
  @media (min-width: 576px) {
    .btn-action-container-qualification {
      font-size: 16px !important; } }

.question-dropdown-item {
  font-size: 14px;
  font-weight: 600;
  color: #0F336F;
  padding: 12px 16px;
  transition-property: background-color;
  transition-duration: 0.3s;
  cursor: pointer;
  transition-timing-function: ease-out; }
  @media (min-width: 576px) {
    .question-dropdown-item {
      font-size: 16px; } }

.question-dropdown-item:hover,
.question-dropdown-item-active {
  color: white;
  border-radius: 8px;
  background-color: #1D66DD; }
