/*
* ink colors (with variations only for black and white)
*/
.q-question-root {
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-direction: column;
  padding: 20px 16px 16px;
  width: 100%; }
  @media (min-width: 576px) {
    .q-question-root {
      gap: 40px; } }
  .q-question-root .qq-text {
    text-align: center;
    color: #0A224A;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; }
    @media (min-width: 576px) {
      .q-question-root .qq-text {
        font-size: 24px;
        line-height: 28px; } }
  .q-question-root .qq-input {
    display: flex;
    flex-direction: column;
    gap: 16px; }
    .q-question-root .qq-input .qq-next {
      padding-top: 24px; }
  .q-question-root .qq-dropdown-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-evenly; }
    .q-question-root .qq-dropdown-date .qq-dropdown-date-input {
      width: 100%; }
